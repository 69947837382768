<template>
  <button
    class="inline-flex items-center gap-2 whitespace-nowrap rounded-sm bg-[#AFAFAF] text-sm font-medium text-white transition hover:bg-red hover:opacity-90"
  >
    <span class="px-2"><slot /> </span>
    <Icon
      class="flex h-6 w-6 items-center rounded-full text-lg"
      name="typcn:times"
    />
  </button>
</template>
